import React, { useEffect, useState } from 'react';
import TranslationEquivalent from './TranslationEquivalent';
import { getDictionaryOrder } from '../Terms/TermDictionaries/TermDictionaries';
import Spinner from 'react-bootstrap/Spinner';
import { useLoadParserData, useParser } from './useParser';
import MandalaSkeleton from '../common/MandalaSkeleton';

export default function TranslationResults({ data }) {
    console.log('data in trans results', data);
    const [dorder, setDOrder] = useState({});

    useEffect(() => {
        getDictionaryOrder().then((data) => setDOrder(data));
    }, []);

    return (
        <div className="results-wrap">
            <h2>Results</h2>
            <div id="results">
                {!data?.words && (
                    <div className="spinner-wrap">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                )}
                {data?.words?.map((w, n) => {
                    return (
                        <TranslationEquivalent
                            key={[w, n]}
                            lookup={w}
                            dictmeta={dorder}
                        />
                    );
                })}
                {data?.undone?.length > 0 && (
                    <LoadTranslations data={data} dictmeta={dorder} />
                )}
            </div>
        </div>
    );
}

function LoadTranslations({ data, dictmeta }) {
    const {
        isLoading: isParserLoading,
        data: results,
        isError: isParsingError,
        error: parseError,
    } = useParser(data);

    if (isParserLoading) {
        return <MandalaSkeleton />;
    }

    // Stop the cycle when there are no more words returned.
    if (!results?.words) {
        return null;
    }

    // Map words to trnaslation equivalents
    const transequivs = results?.words?.map((w, n) => {
        return (
            <TranslationEquivalent
                key={[w, n]}
                lookup={w}
                dictmeta={dictmeta}
            />
        );
    });

    return (
        <>
            {transequivs}
            {results?.undone?.length > 0 && (
                <LoadTranslations data={results} dictmeta={dictmeta} />
            )}
        </>
    );
}
