import {
    getFieldsByString,
    parseMasterDoxId,
    solrFieldValue,
} from '../common/catalogUtils';
import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { SectionIcon } from '../common/SectionIcon';

export function BiblIntelDesc({ bibl }) {
    const doxfields = getFieldsByString(bibl, 'dox_', 'pref').concat(
        getFieldsByString(bibl, 'doxcat_', 'pref')
    );
    const objhomfld = getFieldsByString(bibl, 'homage-obj', 'pref');
    return (
        <div className="c-bibl_inteldesc">
            <h2>
                <SectionIcon />
                Intellectual Description
            </h2>
            <h4>Doxographic Category</h4>
            <ul className="list-unstyled dox-cat-list">
                {doxfields.map((df, dfi) => {
                    const nmpts = df.replace('doxcat_', '').split('_');
                    const lang = nmpts.pop(); // remove language or solr field def
                    const label = nmpts.join(' ');
                    // const lang = nmpts.length > 2 ? nmpts[2] : "en";

                    if (df === 'doxcat_master_ss') {
                        return (
                            <DoxMaster
                                key={`dmi-${dfi}`}
                                dfi={dfi}
                                label={label}
                                lang={lang}
                                field={bibl[df]}
                            />
                        );
                    } else {
                        return (
                            <li key={`doxcat-${dfi}`}>
                                <label className="text-capitalize">
                                    {label}
                                </label>
                                <span className={lang}>{bibl[df]}</span>
                            </li>
                        );
                    }
                })}
                {objhomfld &&
                    objhomfld.map((ohf, ohfi) => {
                        const lang = ohf.replace('homage-obj_', '');
                        // In SOLR doc homage source is included in same field in parentheses
                        // TODO: May want to make child docs for objects of homage if there are multiple
                        const valpts = solrFieldValue(bibl[ohf]).split(' (');
                        // This is problematic result of the indexing, it's value is "info" so do not display here.
                        if (ohf === 'homage-obj_rend_s') {
                            return null;
                        }
                        return (
                            <li key={`objhom-${ohfi}`}>
                                <label>Object of Homage</label>
                                <span className={lang}>{valpts[0]}</span>
                                {valpts.length > 1 && (
                                    <span> ({valpts[1]}</span>
                                )}
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
}

export function DoxMaster({ dfi, label, lang, field }) {
    const [currlang, setCurrLang] = useState('en');
    const selectRef = useRef();

    const path = field?.map((item, n) => {
        let [did, wylie, tibetan, english] = item?.split(' | ');
        did = 'dox/' + parseMasterDoxId(did);
        return (
            <a key={`dmi-${dfi}-${n}`} href={did} className="dox-item">
                {currlang === 'wy' && <span className="wy">{wylie}</span>}
                {currlang === 'bo' && <span className="bo">{tibetan}</span>}
                {currlang === 'en' && <span className="en">{english}</span>}
            </a>
        );
    });

    const setLang = (e) => {
        const val = selectRef.current.value;
        setCurrLang(val);
    };

    return (
        <li key={`doxcat-${dfi}`} className="master-dox">
            <label className="text-capitalize">{label}</label>
            <select
                ref={selectRef}
                className="lang-select"
                aria-label="Language Selection"
                onChange={setLang}
            >
                <option value="en">Eng</option>
                <option value="bo">Tib</option>
                <option value="wy">Wyl</option>
            </select>
            <span className="dox-path">{path}</span>
        </li>
    );
}

//dfi={dfi} label={label} lang={lang} field={bibl[df]} />
