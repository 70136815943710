import React from 'react';
import { getFieldsByString, langNameFromCode } from '../common/catalogUtils';

export function BiblColophon({ bibl }) {
    const coloflds = getFieldsByString(bibl, 'colophon_', 'pref');
    const colos = coloflds.map((cfld, cflind) => {
        const lngcode = cfld.replace('colophon_', '');
        const lngnm = langNameFromCode(lngcode);
        return (
            <li key={`colophon-${lngcode}-${cflind}`}>
                <label>Colophon {lngnm}</label>
                <span className={lngcode}>{bibl[cfld]}</span>
            </li>
        );
    });
    return <>{colos}</>;
}
