import React from 'react';
import { Link } from 'react-router-dom';
import { Iso639M } from './Iso639M/Iso639M';

// TODO: Put Coll data into SOLR and remove from react code
export const defaultCollNames = {
    kt: {
        en: {
            key: false,
            lang: 'en',
            name: 'Kangyur/Tengyur',
        },
        bo: {
            key: false,
            lang: 'bo',
            name: 'བཀའ་འགྱུར་དང་བསྟན་འགྱུར།',
        },
    },
    ngb: {
        en: {
            key: false,
            lang: 'en',
            name: 'Collection Tantras of the Ancients',
        },
        bo: {
            key: false,
            lang: 'bo',
            name: 'རྙིང་མ་རྒྱུད་འབུམ།',
        },
    },
    lccw: {
        en: {
            key: false,
            lang: 'en',
            name: 'Collected Works of Longchenpa',
        },
        bo: {
            key: false,
            lang: 'bo',
            name: 'ཀློང་ཆེན་པ་དྲི་མེད་འོད་ཟེར་གྱི་གསུང་འབུམ།',
        },
    },
};

export function getCollNames(coll) {
    if (!(coll in defaultCollNames)) {
        return false;
    }
    const colldata = defaultCollNames[coll];

    const colllangs = Object.keys(colldata);
    const collnames = colllangs.map((langkey, kind) => {
        return colldata[langkey];
    });
    return collnames;
}

/**
 * Returns a value from the edbibl where field name is like "resource_{type}_s", such as
 *     fields like "resource_scans_s" or "resource_has_sides_s". If the value is a boolean or a number,
 *     it casts the return value into that type
 *
 * @param bibl
 * @param rname
 * @returns {boolean|number|*}
 */
export function getResourceVal(bibl, rname) {
    const ky = `resource_${rname}_s`;
    if (Object.keys(bibl).includes(ky)) {
        let val = bibl[ky];
        if (val === 'false') {
            return false;
        }
        if (val === 'true') {
            return true;
        }
        if (!isNaN(val) && !val.includes('.')) {
            // don't turn paginations into floats.
            return val * 1;
        }
        return val;
    }
}

export function getMainTitle(
    bibl,
    lang2show = process.env.REACT_APP_DEFAULT_LANG
) {
    if (!bibl || typeof bibl === 'string') {
        return {
            idpref: '',
            title: 'No bibl',
            lang: 'en',
        };
    }
    const titles = Array.isArray(bibl['titles'])
        ? bibl['titles']
        : [bibl['titles']];

    const title_idpref =
        bibl.subtype === 'volume' ? `Volume ${bibl.vol_num * 1}: ` : '';

    let title_to_use = titles[0]; // Show first title in list by default (if none more appropriate found)
    // let lang_to_use = lang2show;
    // Look through list for: normalized title, front title, and closing title and use those in that order
    for (let n in titles) {
        let atitle = titles[n];
        if (
            atitle?.type_title_s === 'normalized-title' ||
            atitle?.type_title_s === 'text' // for NGB
        ) {
            title_to_use = atitle;
            break;
        } else if (
            atitle?.type_title_s === 'front' &&
            atitle?.subtype_title_s === 'title-line'
        ) {
            title_to_use = atitle;
            break;
        } else if (
            atitle?.type_title_s === 'back' &&
            atitle?.subtype_title_s === 'closing-section'
        ) {
            title_to_use = atitle;
        }
    }

    // Get the title based on lang2show
    let ttitle = 'Untitled';
    let tlang = 'en';
    if (typeof title_to_use === 'object') {
        const ttlfld = `title_${lang2show}`;
        if (Object.keys(title_to_use).includes(ttlfld)) {
            ttitle = title_to_use[ttlfld];
        } else if (Object.keys(title_to_use).includes('title')) {
            const ttutitle = title_to_use['title'];
            if (Array.isArray(ttutitle) && ttutitle.length > 0) {
                ttitle = ttutitle[0];
            } else if (typeof ttutitle === 'string') {
                ttitle = ttutitle;
            }
        }
    }

    if (ttitle !== 'Untitled' && lang2show) {
        tlang =
            title_to_use && title_to_use['lang'][0]
                ? title_to_use['lang'][0]
                : lang2show;
    }

    if (Array.isArray(ttitle)) {
        ttitle = ttitle.filter((t) => {
            return t?.length > 0;
        });
    }

    // Return title object with idpref, title, and lang
    return {
        idpref: title_idpref,
        title: ttitle,
        lang: tlang,
    };
}

/**
 * Given a collection bibl, returns the collection title
 * @param bibl
 */
export function getCollTitle(bibl, lang = 'en') {
    const fldnm = `title_${lang}`;
    let title = '';
    if (Object.keys(bibl).includes(fldnm)) {
        title = Array.isArray(bibl[fldnm]) ? bibl[fldnm][0] : bibl[fldnm];
    } else if (bibl?.titles?.length > 0) {
        title = bibl.titles[0];
    }
    return title;
}

/**
 * Gets titles from doxcat text record for Dox toc volumes
 *
 * @param doxcat
 * @param biblang
 * @returns {{idpref: string, title: string, lang}}
 */
export function getDoxTitle(doxcat, biblang = 'default') {
    let title_idpref = '';
    let tfield = `title_${biblang}`;
    let ttitle = 'Untitled.';
    if (Object.keys(doxcat).includes(tfield)) {
        ttitle = doxcat[tfield];
    } else if (typeof doxcat?.title === 'string') {
        ttitle = doxcat.title;
    } else if (doxcat?.title?.length > 0) {
        ttitle = doxcat.title[0];
    }
    return {
        idpref: title_idpref,
        title: ttitle,
        lang: biblang,
    };
}

export function getDoxTitleSimple(doxcat, biblang = 'default') {
    let doc_title = 'No Title.';
    if (typeof doxcat === 'undefined') {
        return doc_title;
    }
    const doclang = biblang === 'default' ? getNormalizedLang(doxcat) : biblang;
    const titlefld = `title_${doclang}`;
    if (Object.keys(doxcat).includes(titlefld)) {
        doc_title = <span className={doclang}>{doxcat[titlefld]}</span>;
    } else if (Array.isArray(doxcat?.title) && doxcat.title.length > 0) {
        doc_title = doxcat.title[0];
    } else if (doxcat?.subtype === 'volume') {
        doc_title = doxcat?.label_s
            ? doxcat.label_s
            : `Volume ${doxcat?.pos_i}`;
    }
    return doc_title;
}

export function getDoxRange(doxcat, debug = false) {
    const coll = doxcat?.coll;
    const ed = doxcat?.edsig;
    const pref = buildSigla(coll, ed);
    const txtnums = doxcat?.texts_ss?.map((tstr, i) => {
        return tstr * 1;
    });

    // TODO:
    if (!txtnums || doxcat?.children_ss?.length > 0) {
        return `${pref}${doxcat?.st_text_i}-${pref}${doxcat?.end_text_i}`;
    }
    const txtrngs = makeRanges(txtnums);
    return `${pref}${txtrngs.join(', ')}`;
}

export function makeRanges(numlist) {
    if (!Array.isArray(numlist)) {
        console.log('Numlist is not an array', numlist);
        return [numlist];
    }
    // Taken from https://stackoverflow.com/a/28431102 (July 20, 2023)
    const rlists = numlist.reduce((r, n) => {
        const lastSubArray = r[r.length - 1];
        if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
            r.push([]);
        }
        r[r.length - 1].push(n);
        return r;
    }, []);

    return rlists.map((rl, i) => {
        const item1 = rl[0] * 1;
        const itemlast = rl.pop() * 1;
        if (item1 === itemlast) {
            return item1;
        }
        return `${rl[0]}-${itemlast}`;
    });
}

export function buildSigla(coll, edsig, sep = '.') {
    if (sep === '.') {
        coll = capitalize(coll);
    }
    let edsigcalc = '';
    if (edsig !== 'main' && edsig?.length > 0) {
        if (sep === '.') {
            edsigcalc = sep + capitalize(edsig);
        } else {
            edsigcalc = sep + edsig;
        }
    }
    return `${coll}${edsigcalc}${sep}`;
}

/**
 * Takes a bibl titles list and finds the title for the given language
 * @param titles
 * @param lang
 */
export function getTitleByLang(titles, lang) {
    if (!Array.isArray(titles)) {
        // console.log("in getTitle by Lang titles is not an array", titles);
        return null;
    }
    const langTitles = titles.filter((t, tind) => {
        return t.lang && t.lang?.length > 0 && t.lang[0] === lang;
    });
    let outtitles = langTitles.map((t, tind) => {
        let title = t.title;
        let langtitle = t[`title_${lang}`];
        if (typeof langtitle !== 'undefined') {
            title = langtitle;
        }
        if (Array.isArray(title)) {
            title = title[0];
        }
        return <span className={lang}>{title}</span>;
    });

    return outtitles?.length > 0 ? (
        outtitles[0]
    ) : (
        <span className="en">Untitled</span>
    );
}

export function hasField(obj, fnm) {
    const okeys = Object.keys(obj);
    return okeys?.includes(fnm);
}

export function solrFieldValue(solrfield) {
    if (Array.isArray(solrfield) && solrfield.length > 0) {
        return solrfield[0];
    }
    return solrfield;
}

export function buildTextId(coll, ed, tnum, idtype = 'bib') {
    let fullid = `${coll}-`;
    if (ed !== 'main') {
        fullid += ed;
    }
    fullid += tnum.toString().padStart(4, '0');
    return `${fullid}-${idtype}`;
}

/**
 * Function to return a span with class with the language code contained in the field name
 * Assumes the lang code is right after field name prefix (fnp)
 *
 * @param bibl
 * @param fnm
 */
export function getLangField(bibl, fnp) {
    const flds = getFieldsByString(bibl, fnp, 'pref');
    const pattern = new RegExp(fnp + '_([^_]+)(_|$)'); // Find the next string between underscores after prefix (fnp)
    return (
        <>
            {flds.map((fldnm, fldind) => {
                let mmtch = fldnm.match(pattern);
                if (mmtch) {
                    let lcd = langcode(mmtch[1]);
                    return (
                        <span key={`${fnp}-lang-${fldind}`} className={lcd}>
                            {bibl[fldnm]}
                        </span>
                    );
                }
                return null;
            })}
        </>
    );
}

export function getLangFromString(instr) {
    let lng = 'en';
    const c1cd = instr.charCodeAt(0);
    if (c1cd > 3839 && c1cd < 4096) {
        lng = 'bo';
    } else if (c1cd > 2303 && c1cd < 2432) {
        lng = 'sa';
    } else if (c1cd > 19967 && c1cd < 40960) {
        lng = 'zh';
    }
    return lng;
}

export function getBiblId(bibl) {
    if (!bibl || !bibl?.coll) {
        return '';
    }
    if (bibl.dox !== '') {
        return bibl.dox;
    }
    const isvol = !bibl?.text && bibl?.vol;
    const num = bibl?.text || bibl?.vol;
    return buildBiblId(bibl.coll, bibl?.ed, num, isvol);
}

export function buildBiblId(coll, ed, txt = 0, bibtype = 'text') {
    let colledstr = ed === 'main' ? `${coll}` : `${coll}-${ed}`;
    const isVol = bibtype === 'vol' || bibtype === 'volume';
    const pad = isVol ? 3 : 4;
    let txtvolstr = '';
    if (txt && txt !== 0) {
        txtvolstr = txt.toString().padStart(pad, '0');
        if (isVol) {
            txtvolstr = `v${txtvolstr}`;
        }
        txtvolstr = `-${txtvolstr}`;
    }
    return `${colledstr}${txtvolstr}-bib`;
}

export function getBiblLang(bibl) {
    let lang = false;
    // console.log(bibl);
    if (bibl) {
        if (Object.keys(bibl).includes('langcode')) {
            lang = bibl['langcode'];
        } else if (Object.keys(bibl).includes('lang')) {
            lang = bibl['lang'];
        }
        if (Array.isArray(lang)) {
            lang = lang[0];
        }
    }
    return langcode(lang);
}

export function getLangTypedField(bibl, pref, suff) {
    let langcode = getBiblLang(bibl);
    const fieldval = getLangTypedFieldVal(bibl, pref, suff);
    if (fieldval) {
        return <span className={langcode}>{fieldval}</span>;
    } else {
        return null;
    }
}

/**
 * Gets a specific field with a langcode appended to the field name
 *
 * @param bibl
 *    The object with the data fields, generally a bibl but can be a section object
 * @param pref
 *    The string prefixed to the lang code, e.g. if you are looking for a specific lang title, "title" would become
 *    "title_bo", etc.
 * @param suff
 *    Any suffix after the lang code, if there were .e.g. "title_bo_weird" this would be "weird".
 * @returns {*|boolean}
 */
export function getLangTypedFieldVal(bibl, pref, suff) {
    const langcode = getBiblLang(bibl);
    if (langcode === 'bod') {
        console.log('Langcode: ', langcode);
    }
    let field = `${pref}_${langcode}`;
    if (typeof suff !== 'undefined') {
        field += `_${suff}`;
    }
    if (Object.keys(bibl).includes(field)) {
        return solrFieldValue(bibl[field]);
    }
    if (field === 'ed_bod') {
        console.log('edbod', bibl);
    }
    // console.log(`Could not find ${field} in bibl data`);
    return false;
}

export function getDefaultField(doc, fieldname) {
    const deflang = process.env?.REACT_APP_DEFAULT_LANG || 'en';
    let field_val = doc[fieldname]; // default value without lang
    const fnlang = `${fieldname}_${deflang}`;
    if (Object.keys(doc).includes(fnlang)) {
        field_val = doc[fnlang];
    }
    if (field_val?.length > 0) {
        if (Array.isArray(field_val)) {
            return field_val[0];
        } else {
            return field_val;
        }
    }
    return false;
}

export function getBoField(bibl, pref, suff) {
    let tibval =
        typeof suff === 'undefined'
            ? getLangTypedFieldVal(bibl, pref)
            : getLangTypedFieldVal(bibl, pref, suff);
    let wylval =
        typeof suff === 'undefined'
            ? getLangTypedFieldVal(bibl, pref, 'wy')
            : getLangTypedFieldVal(bibl, pref, suff + '_wy');
    if (wylval && typeof wylval !== 'undefined') {
        return (
            <span className="bo" title={`${wylval}`} data-wylie={wylval}>
                {tibval}
            </span>
        );
    }
    return <span className="bo">{tibval}</span>;
}

export function getNormalizedLang(obj) {
    let lang = obj?.lang;
    if (!lang) {
        return process.env.REACT_APP_DEFAULT_LANG;
    }
    lang = Array.isArray(lang) ? lang[0] : lang;
    lang = langcode(lang);
    return lang && lang?.length === 2
        ? lang
        : process.env.REACT_APP_DEFAULT_LANG;
}

/**
 * Finds field names in an object that contain a string in a certain position
 * @param {object} obj - the object being searched
 * @param {string} sstr - the substring to be found in field names
 * @param {string} pos -  optional position where the substring is location:
 *                        "pref" : as a prefix at the beginning of the field name
 *                        "suff" : suffix at the end of the field name
 *                        "contains" : (default) anywhere in the field name
 * @returns {string[]}
 */
export function getFieldsByString(obj, sstr, pos = 'contains') {
    const keys = Object.keys(obj);
    return keys.filter((k) => {
        if (pos === 'pref') {
            return k.startsWith(sstr);
        } else if (pos === 'suff') {
            return k.endsWith(sstr);
        } else {
            return k.includes(sstr);
        }
    });
}

export function getPagination(bibl, abbrev = true) {
    const vollabel = abbrev ? 'vol.' : 'volume';
    const stvol = bibl?.vol_start_i || bibl?.vol_num;
    const endvol = bibl?.vol_end_i || bibl?.vol_num;

    let stpg = bibl?.pg_start_text_s || bibl?.pg_start_s;
    let endpg = bibl?.pg_end_text_s || bibl?.pg_end_s;

    stpg = onePageMarkup(stpg, stvol, vollabel);
    if (stvol === endvol) {
        endpg = onePageMarkup(endpg);
    } else {
        endpg = onePageMarkup(endpg, endvol, vollabel);
    }

    return (
        <span className="bibl__pagination">
            {stpg} - {endpg}
        </span>
    );
}

export function onePageMarkup(folio, volnum = '', vollabel = '') {
    const volmarkup =
        volnum !== '' ? (
            <span className="bibl__page--vol">
                <span className="label">{vollabel}</span>{' '}
                <span className="num">{volnum}</span>
            </span>
        ) : null;
    return (
        <span className={'bibl__page'}>
            {volmarkup}
            <span className="bibl__page--folio">{folio}</span>
        </span>
    );
}

export function getNote(data) {
    let notefield = Object.keys(data).filter((kn, i) => {
        return kn.startsWith('note_');
    });
    if (notefield.length > 0) {
        // TODO: return more than one (maybe?)
        if (data[notefield[0]]) {
            let notedata = Array.isArray(data[notefield[0]])
                ? data[notefield[0]][0]
                : data[notefield[0]];
            return notedata.replace(/\\[ntr]/g, ' ');
        }
    }
    return false;
}

// Returns the value contained in solr field. If it's an array, returns first value.
export function getSolrVal(fval) {
    if (Array.isArray(fval)) {
        return fval[0];
    }
    return fval;
}

/**
 * Parses a edition resource string from a solr record. For instance, "resource_digtexts_s":"1-1117" or
 * "resource_scans_s":"0". This function takes the value such as "1-1117" or "0" and parses it into
 * an array of [start, end] numbers. Or returns false if the value is none-ish.
 *
 * This function could be enhanced to parse other values if necessary
 *
 * @param res
 * @returns {boolean|number[]}
 */
export function parseResourceRange(res) {
    if (!res || res * 1 === 0 || res === '' || res === 'none') {
        return false;
    }
    // Parse input string differently depending on format
    if (!isNaN(res)) {
        // If it's a single number, cast it to a number
        res = res * 1;
        return [res];
    } else if (res.includes('-') || res.includes(',')) {
        // console.log("res", res);
        let rout = [];
        const respts = res.split(',');
        for (let pn in respts) {
            let rpt = respts[pn].trim();
            if (!isNaN(rpt)) {
                rout.push(rpt * 1);
            } else if (rpt.includes('-')) {
                // If it's a range, return an array of all numbers in the range
                const rngpts = rpt.split('-');
                if (!isNaN(rngpts[0]) && !isNaN(rngpts[1])) {
                    const st = rngpts[0] * 1;
                    const en = rngpts[1] * 1;
                    rout = [...rout, ...Array(en - st + 1).keys()].map((x) => {
                        return x + st;
                    });
                }
            }
        }
        return rout;
    }
    return res; // Otherwise, return the original string unparsed
}

export function inRange(anum, arange) {
    anum = anum * 1;
    // If the range is a list with more than two terms, it is an individual list, just see if number is in it.
    if (arange?.length > 2) {
        return inList(anum, arange);
    }
    const startnum = arange?.length > 0 ? arange[0] * 1 : false;
    let endnum = arange?.length > 1 ? arange[1] * 1 : startnum;

    if (isNaN(anum) || !startnum) {
        return false;
    }
    return anum >= startnum && anum <= endnum;
}

export function inList(anum, arange, sep = ',') {
    anum = anum * 1;
    if (typeof arange === 'string') {
        arange = arange.split(sep);
        arange = arange.map((it, itn) => {
            try {
                return parseInt(it);
            } catch (e) {
                return 0;
            }
        });
    }
    // If the range is a list with more than two terms, it is an individual list, just see if number is in it.
    if (Array.isArray(arange)) {
        return arange.indexOf(anum) > -1;
    }
}

export function getPagePath(edbib, pg) {
    /*
resource_digtexts_s: "0"
resource_master-cat_s: "ngb/ng/ngb-ng-cat.xml"
resource_master_id_s: "ng-work-bib"
resource_pgimg_dir_s: "https://www.thlib.org/static/catalogs/ngb/tb/"
resource_pgimg_id_s: "ngb-tb-v__VOL__-p__PAGE__"
resource_pgimg_path_s: "v__VOL__/__PGID__.jpg"
resource_scans_s: "1-939"  // Text numbers that have scans
resource_txtpg_id_s: "v__VOL__p__PAGE____SIDE__"
resp_cataloguer_s: "ndg"
resp_history_of_the_edition_s: "dfg"
 */
    // console.log("pg in get path", pg);
    // console.log("edbib", edbib);
    const zvol = zfill(pg?.vol, 3);
    const zpg = zfill(pg?.page, 4);
    let pgvars = {
        vol: zvol,
        page: zpg,
    };
    if (pg?.side?.length > 0) {
        pgvars['side'] = pg.side;
    }
    const pgimg_id = translateResource(edbib?.resource_pgimg_id_s, pgvars);
    let pgimg_path = translateResource(edbib?.resource_pgimg_path_s, {
        vol: zvol,
        pgid: pgimg_id,
    });
    pgimg_path = edbib?.resource_pgimg_dir_s + pgimg_path;
    // console.log("pgimg path in function", pgimg_path);
    return pgimg_path;
}

/**
 * Takes a resource string such as "resource_pgimg_id_s":"kt-d-v__VOL__-p__PAGE__", and an object maded up of
 * repstr => val pairs, e.g. {"vol": 23, "page": "67b"} and translates the generic string into the  specific one
 *
 * @param resstr
 * @param vals
 * @returns {*}
 */
export function translateResource(resstr, vals) {
    if (typeof resstr !== 'string' || typeof vals !== 'object') {
        return resstr;
    }
    Object.keys(vals).map((ky, kn) => {
        resstr = resstr.replace(`__${ky.toUpperCase()}__`, vals[ky]);
    });
    return resstr;
}

/**
 * Parses a pagination string into an object. Can have start and end pagination separated by a dash
 * or just a single start pagination. Paginations can include volume, e.g., v1:245a.6-v1:258b.3 or not, 314b.2
 * For now it requires a side, a or b. TODO: generalize so side is not necessary.
 *
 * @param pgn
 * @returns {{}|boolean}
 */
export function parsePagination(pgn) {
    if (!pgn || typeof pgn !== 'string') {
        return false;
    }
    // Pagination Format is:      v1:245a.6-v1:258b.3
    const pgnpt = pgn.split('-');
    let pgobj = {};
    if (pgn[0] === 'v') {
        const rx = /v(\d+):(\d+)([ab])\.(\d)/;
        const res = pgnpt[0]?.match(rx);
        if (res) {
            pgobj['hasvol'] = true;
            pgobj['start'] = {
                vol: res[1],
                page: res[2],
                side: res[3],
                line: res[4],
            };
        }

        const res2 = pgnpt[1]?.match(rx);
        if (res2) {
            pgobj['end'] = {
                vol: res2[1],
                page: res2[2],
                side: res2[3],
                line: res2[4],
            };
        }
    } else {
        pgobj['hasvol'] = false;
        const rx2 = /(\d+)([ab])\.(\d)/;
        const res = pgnpt[0]?.match(rx2);
        if (res) {
            pgobj['start'] = {
                page: res[1],
                side: res[2],
                line: res[3],
            };
        }

        const res2 = pgnpt[1]?.match(rx2);
        if (res2) {
            pgobj['end'] = {
                page: res2[1],
                side: res2[2],
                line: res2[3],
            };
        }
    }

    return pgobj;
}

export function paginationToString(pgn) {
    // For some instances of strings like: v1:34b.7-v1:45b.6
    const mtch = pgn.match(/v(\d+):(\d+)([ab])\.(\d)-v(\d+):(\d+)([ab])\.(\d)/);
    if (mtch) {
        pgn = {
            start: {
                vol: mtch[1],
                page: mtch[2],
                side: mtch[3],
                line: mtch[4],
            },
            end: {
                vol: mtch[5],
                page: mtch[6],
                side: mtch[7],
                line: mtch[8],
            },
        };
    }
    const st = pgn?.start;
    if (!st) {
        return '';
    }
    const en = pgn?.end;
    let pgnstr = `Vol. ${st.vol}, ${st.page}${st.side}.${st.line}`;
    if (en) {
        pgnstr += '-';
        if (en.vol !== st.vol) {
            pgnstr += `Vol. ${en.vol}, `;
        }
        pgnstr += `${en.page}${en.side}.${en.line}`;
    }
    return pgnstr;
}

export function inPaginationRange(pgdata, pagination) {
    // pgdata = array[vol, pg, side]
    // pagination format: v1:99a.2-v1:111a.4
    const pgpts = pagination.split('-');
    const startpg = pageStrToObj(pgpts[0]);
    let endpg = startpg;
    if (pgpts?.length == 2) {
        endpg = pageStrToObj(pgpts[1]);
    }
    return pageInRange(...pgdata, startpg, endpg);
}

export function pageStrToObj(pgstr) {
    let mtch = pgstr.match(/v(\d+):(\d+)([ab])\.?(\d*)/);
    if (mtch) {
        return {
            vol: mtch[1] * 1,
            page: mtch[2] * 1,
            side: mtch[3],
            line: mtch[4] ? mtch[4] * 1 : '',
        };
    }
    mtch = pgstr.match(/v(\d+)p(\d+)([ab]?)/);
    if (mtch) {
        return {
            vol: mtch[1] * 1,
            page: mtch[2] * 1,
            side: mtch[3],
        };
    }
    return false;
}

export function pageInRange(vol, pg, sd, stpg, endpg) {
    vol = vol * 1;
    if (vol < stpg.vol || vol > endpg.vol) {
        return false;
    }
    pg = pg * 1;
    if (pg < stpg.page || pg > endpg.page) {
        return false;
    }
    return !(
        (pg === stpg.page && sd < stpg.side) ||
        (pg === endpg.page && sd > endpg.side)
    );
}

export function prevPage(pg, sd) {
    if (!sd || sd === '') {
        return [pg * 1 - 1, ''];
    }
    if (sd === 'a') {
        sd = 'b';
        pg = pg * 1 - 1;
        if (pg < 1) {
            pg = 1;
        }
    } else {
        sd = 'a';
    }
    return [pg, sd];
}

export function nextPage(pg, sd) {
    if (!sd || sd === '') {
        return [pg * 1 + 1, ''];
    }
    if (sd === 'a') {
        sd = 'b';
    } else {
        pg = pg * 1 + 1;
        sd = 'a';
    }
    return [pg, sd];
}

/**
 * Sorts a list of bibl objects by a field that is itself a list.
 * Written to sort by title in SOLR docs which are lists and cannot be
 * @param blist
 * @param field
 */
export function sortBibls(blist, field = 'title') {
    return blist.sort((x, y) => {
        let xf = x[field];
        let yf = y[field];
        if (Array.isArray(xf) && xf.length > 0) {
            xf = xf[0];
        }
        if (Array.isArray(yf) && yf.length > 0) {
            yf = yf[0];
        }
        if (xf < yf) {
            return -1;
        }
        if (xf > yf) {
            return 1;
        }
        return 0;
    });
}

export function zfill(num, size) {
    if (!num) {
        return num;
    }
    num = num.toString();
    while (num.length < size) num = '0' + num;
    return num;
}

/**
 * Converts XML id, e.g. 'b3-1-5-4' into a label '2.3.1.5.4'
 * @param sctid
 * @returns {*}
 */
export function convertToSectionLabel(sctid) {
    if (typeof sctid === 'string') {
        sctid = sctid
            ?.replace('a', '1.')
            .replace('b', '2.')
            .replace('c', '3.')
            .replace(/\-/g, '.');
    }
    return sctid;
}

/**
 * The reverse of above, turns a label back into an id: '3.1.2' => 'c1-2'
 * @param sctlabel
 * @returns {*}
 */
export function convertToSectionId(sctlabel) {
    if (!sctlabel || !sctlabel?.split) {
        return '';
    }
    let pts = sctlabel.split('.');
    let sectlet = 'b';
    if (pts[0] === '1') {
        sectlet = 'a';
    }
    if (pts[0] === '3') {
        sectlet = 'c';
    }
    pts.shift(); // remove first part
    pts[0] = sectlet + pts[0]; // join the section letter to the first section number
    return pts.join('-');
}

/**
 * Takes a section title with a mixed string of number and title text and splits it into
 * an array of the number and title. The section number can be any combination of digits and periods.
 *
 * @param title
 * @returns {(string|*)[]|*[]}
 */
export function splitNumberedTitle(title) {
    if (typeof title !== 'string') {
        return title;
    }
    const regex = /([\d\.]+)\s*([\s\S]+)/; // splits off a preceding combination of digits and periods
    const found = title.match(regex);
    if (found) {
        return [found[1], found[2]];
    }
    return ['', title]; // returns a two item array with item 1 the number (if found) and item 2 the title
}

export function getLinkFromId(iid) {
    if (typeof iid !== 'string' || !iid.includes('-')) {
        return iid;
    }
    const idpts = iid.split('-');
    // Section ID when no ed in url as in LCCW
    if (idpts?.length === 5 && idpts[2] === 'text' && idpts[4] === 'text') {
        const [coll, tnum, t1, sid, t2] = idpts;
        return (
            <Link to={`/catalog/${coll}/main/${tnum}/text/section/${sid}`}>
                View
            </Link>
        );
    }
}

/**
 * Function to parse master dox ids (only for NGB for now)
 * TODO: Generalize for other master catalogs...
 * @param did
 * @returns {string}
 */
export function parseMasterDoxId(did) {
    let newid = '';
    switch (did) {
        case 'ngb-ng1':
            newid = 'ngb-ng-ati';
            break;
        case 'ngb-ng2':
            newid = 'ngb-ng-anu';
            break;
        case 'ngb-ng3':
            newid = 'ngb-ng-maha';
            break;
        case 'ngb-ng4':
            newid = 'ngb-ng-supp';
            break;
        case 'ngb-ng5':
            newid = 'ngb-ng-app';
            break;
        default:
            newid = did.replace('ngb-ng', 'ngb-ng-').replace('--', '-');
    }
    return newid + '-dox';
}

/**
 * langcode: get the langcode of the desired length, default to 2 (i.e. translate from 3 to 2 letter or vice versa)
 *  e.g. langcode('bo', 3) returns 'tib', while langcode('tib') returns 'bo'
 * @param lcd
 * @returns {string|*}
 */
export function langcode(lcd, lgth = 2) {
    if (Array.isArray(lcd)) {
        lcd = lcd[0];
    }
    if (lcd?.length === 2) {
        return lgth === 2 ? lcd : Iso639M.convertLangCode(lcd);
    } else if (lcd?.length === 3) {
        return lgth === 3 ? lcd : Iso639M.convertLangCode(lcd);
    }
    return lcd;
}

/**
 * Function uses RegEx on a field name to see if it has any lang code embedded in it
 *
 * @param fnm
 * @returns {boolean}
 */
export function langcodeFromField(fnm) {
    let fieldcode = false;
    const allLangs = Iso639M.getAllCodes();
    for (let ln in allLangs) {
        let lngcode = allLangs[ln];
        let pat = RegExp('_' + lngcode + '(_|$)'); // lang code either followed by _ or at end of string
        if (fnm.match(pat)) {
            fieldcode =
                lngcode?.length === 3
                    ? Iso639M.convertLangCode(lngcode)
                    : lngcode; // convert 3 letter to 2 letter codes
            break;
        }
    }
    return fieldcode;
}

export function isLangCode(lcd) {
    return Iso639M.langExists(lcd);
}

export function langNameFromCode(lcd) {
    if (Array.isArray(lcd)) {
        if (lcd.length == 0) {
            return null;
        }
        lcd = lcd[0];
    }
    if (['wy', 'bo_wy', 'bo_latn'].includes(lcd)) {
        return 'Wylie';
    }
    if (!isLangCode(lcd)) {
        return lcd;
    }
    return Iso639M.getLangNameFromCode(lcd);
}

export function listChunk(arr, len) {
    var chunks = [],
        i = 0,
        n = arr.length;

    while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
    }

    return chunks;
}

export function capitalize(mystr, force = false) {
    if (typeof mystr === 'string' && mystr.length > 0) {
        const firstchar = mystr.charAt(0).toUpperCase();
        const reststr = force
            ? mystr.substring(1).toLowerCase()
            : mystr.substring(1);
        return firstchar + reststr;
    }
    return mystr;
}

/**
 * Takes a solr facet_fields list and processes it into a dictionary of facets
 * @param fdata
 * @returns {{}}
 */
export function processFacets(fdata) {
    let ffdata = fdata.facet_fields;
    let facets = {};
    let fctdata;
    Object.keys(ffdata).forEach((fname, fni) => {
        fctdata = {};
        for (let i = 0; i < ffdata[fname].length; i += 2) {
            let fkey = ffdata[fname][i];
            let fval = ffdata[fname][i + 1];
            fctdata[fkey] = fval;
        }
        facets[fname] = fctdata;
    });
    return facets;
}

/**
 * Uses the facets field of the SOLR response (but outside the response field of the response) which are JSON.facets.
 * Parses these into an object keyed on facet name with each facet being an object keyed on item id with counts.
 * As of this writing, there are 3 such facets: coll, texthits, and type. "texthits" are the tibbibl IDs with
 * the number of hits in that text whether in bibl record or in text. The "type" is the number of hits in each
 * type of document for faceting. And the "coll" is keyed on collection ID and for each has an object with a total
 * number of hits and an "eds" objected keyed on edition sigla with number of hits for each edition. Editions
 * with the id of "main" or "" are filtered out.
 *
 * @param fdata
 * @returns {{}}
 */
export function processJsonFacets(fdata) {
    // console.log("fdata in process json facets", fdata);
    // const totalFacetCt = fdata?.count;
    const newFacets = {}; // Object to build new factes in
    // Get the facet names and use them as keys for newFacets with the number of its as its value
    const facetnames = Object.keys(fdata).filter((fnm) => fnm !== 'count');
    // Create an object for each facet name with either a count or an object with total and subfacets
    facetnames.forEach((fnm) => {
        const flist = {};
        const fbuckets = fdata[fnm].buckets; // get the buckets for this facet
        // Iterate through the values of this facet
        fbuckets.forEach((fbkt) => {
            flist[fbkt.val] = fbkt.count; // default if no subfacets is just the count
            // But if there are subfacets, create an object ...
            const subfacets = Object.keys(fbkt).filter(
                (k) => !['val', 'count'].includes(k)
            );
            if (subfacets.length > 0) {
                // Make a subfacet object with total as the first key
                const sflist = {
                    total: fbkt.count,
                };
                // iterate through the subfacets and add keys of the facet.val with their value being the count
                subfacets.forEach((sf) => {
                    fbkt[sf].buckets.forEach((sfk) => {
                        sflist[sfk.val] = sfk.count;
                    });
                });
                // assign the facet object with subfacets to this facet key
                flist[fbkt.val] = sflist;
            }
        });
        // Add this facet object to the new facets object keyed on facet name
        newFacets[fnm] = flist;
    });
    return newFacets; // and return the whole thing.
}

export function getNow() {
    const dt = new Date();
    return dt.getTime() + dt.getMilliseconds();
}

export function getPreviousInList(item, list, loop = false) {
    const iind = list.indexOf(item);
    if (iind > 0) {
        return list[iind - 1];
    }
    if (loop && iind === 0) {
        return list[list.length - 1];
    }
    return false;
}

export function getNextInList(item, list, loop = false) {
    // console.log('in get next', item, list);
    const iind = list.indexOf(item);
    let retval = false;
    if (iind < list.length - 1) {
        retval = list[iind + 1];
    }
    if (loop && iind > 0) {
        retval = list[0];
    }
    // console.log("returning", retval);
    return retval;
}

export function siblingOrder(a, b) {
    const anum = a.pos_i * 1;
    const bnum = b.pos_i * 1;
    if (anum > bnum) {
        return 1;
    }
    if (bnum > anum) {
        return -1;
    }
    return 0;
}

export function scrollToItemInToc(tocid) {
    setTimeout(() => {
        const el = document?.getElementById(tocid);
        const parent = document
            ?.getElementsByClassName('l-frame-toc')[0]
            ?.getElementsByClassName('l-frame-wrapper')[0];
        if (parent && el) {
            scrollParentToChild(parent, el);
        }
        // console.log("Scroll to", tocid, el);
    }, 1000);
}

function scrollParentToChild(parent, child) {
    // Taken from https://stackoverflow.com/a/45411081
    // Where is the parent on page
    var parentRect = parent.getBoundingClientRect();
    // What can you see?
    var parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth,
    };

    // Where is the child
    var childRect = child.getBoundingClientRect();
    // Is the child viewable?
    var isViewable =
        childRect.top >= parentRect.top &&
        childRect.bottom <= parentRect.top + parentViewableArea.height;

    // if you can't see the child try to scroll parent
    if (!isViewable) {
        // Should we scroll using top or bottom? Find the smaller ABS adjustment
        const scrollTop = childRect.top - parentRect.top;
        const scrollBot = childRect.bottom - parentRect.bottom;
        const diff = parentViewableArea.height / 1.5; // to middle of div.
        if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
            // we're near the top of the list
            // const newscrolltop = parent.scrollTop + scrollTop + diff;
            parent.scrollTop += scrollTop + diff;
        } else {
            // we're near the bottom of the list
            parent.scrollTop += scrollBot + diff;
        }
    }
}
