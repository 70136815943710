import React, { useEffect } from 'react';
import { BiblAgents, getBiblAgents } from './BiblAgents';
import { BiblColophon } from './BiblColophon';
import { getFieldsByString } from '../common/catalogUtils';
import { SectionIcon } from '../common/SectionIcon';

export function BiblProvenance({ bibl }) {
    return (
        <div className="c-bibl_provenance">
            <h2>
                <SectionIcon />
                Provenance
            </h2>
            <ul className="list-unstyled">
                <BiblAgents bibl={bibl} />
                <BiblColophon bibl={bibl} />
            </ul>
        </div>
    );
}
